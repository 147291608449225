
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import Toast from '@/components/utilities/Toast.vue';
import InputFile from '@/components/utilities/InputFile.vue';
import SvgIcon from '@/components/utilities/SvgIcon.vue'
import { verifLevels, auth } from '@/store/namespaces';
import VerifLevelsMethods from '@/store/verificationLevels/methods/verificationLevels.methods';
import { Answer, Question } from '@/modules/verificationLevels/interfaces/questions.interface';
import {ReqLevelTwo} from '@/modules/verificationLevels/interfaces/requests.interface';
import { required, minLength, helpers,requiredIf } from 'vuelidate/lib/validators';
import { showToast } from '@/utils/global-functions';
import Loader from '@/components/utilities/Loader.vue';
import { availableImagesTypes } from '@/utils/constants';
import { _arrayBufferToBase64 } from '@/utils/image-functions';
import AuthMethods from '@/store/auth/methods/auth.methods';
import { User } from '@/modules/auth/interfaces/user.interface';

@Component({
    components: {
        Toast,
        InputFile,
        SvgIcon,
        Loader
    },
    mixins: [validationMixin],
    validations:{
        localRequirements:{
            monthly_amount:{
                value:{required,minLength: minLength(1)}
            }, 
            funds_source: {
                value: {required,minLength: minLength(1)}
            },
            job_title: {
                value: {required}
            },
            work_industry:{
                value:{required,minLength: minLength(1)}
            },
            other_industry:{
                value:{
                    // required
                    // requiredIf(function(){
                    //         // @ts-ignore
                    //         if (this.localRequirements.work_industry.value[0].answer === 'Otros.')
                    //             return true
                    // })
                    requiredIf: requiredIf(function(){
                        // @ts-ignore
                        if (this.localRequirements.work_industry.value[0].answer === 'Otros.' && this.preSelectedWorkIndustry === '')
                            return true
                    })
                }
            },
           residency_proof:{
               value: {required}
           }
        }
    }
})
export default class SecondStep extends Vue {  
    loader = true;
    $refs : any ={}
    monthlyAmounts = [];
    workIndustries = [];
    fundSources = [];
    localRequirements : ReqLevelTwo; 
    localLevelQuestions = [];
    workIndustryResponse = false;
    imageAlreadyExists = false;
    formats: Array<string> = [];
    preSelectedWorkIndustry = '';
    currentWorkIndustry = {};
    preSelectedMonthlyAmount = '';

    async created(){
        this.formats = availableImagesTypes;  
        this.localRequirements = this.levelTwoRequest;
        await this.fetchQuestions(this.userData.id_resid_country);
        if (this.levelTwoRequest.residency_proof.rejected === false)
            this.imageAlreadyExists = true
        else 
            this.levelTwoRequest.residency_proof.value = ''
        this.localLevelQuestions = this.levelQuestions
        this.monthlyAmounts = this.levelQuestions[0].answers; // Cambiar 
        this.workIndustries = this.levelQuestions[1].answers; // Cambiar 
        this.fundSources = this.levelQuestions[2].answers; // Cambiar
        // this.checkWorkIndustry();
        console.log('this.localRequirements.work_industry',this.localRequirements.work_industry)
        if (this.userData && this.userData.extra_info && this.userData.extra_info.third_modal && this.userData.extra_info.third_modal.items && this.userData.extra_info.third_modal.items.length > 0 && this.userData.extra_info.third_modal.items.find(el => el.name === 'Industria en la que trabaja') && (!this.localRequirements.work_industry.rejected || this.localRequirements.work_industry.rejected === null)){
            this.preSelectedWorkIndustry = this.userData.extra_info.third_modal.items.find(el => el.name === 'Industria en la que trabaja').value;
            this.currentWorkIndustry = this.workIndustries.find(el => el.answer === this.preSelectedWorkIndustry);
            if (!this.currentWorkIndustry){
                this.currentWorkIndustry = this.workIndustries.find(el => el.answer === 'Otros.')
                this.localRequirements.work_industry.value.push(this.currentWorkIndustry)
                // currentWorkIndustry.answer = preSelectedWorkIndustry
            }
            this.selectWorkIndustry(this.currentWorkIndustry)
        } else if (this.userData && this.userData.extra_info && this.userData.extra_info.third_modal && this.userData.extra_info.third_modal.items && this.userData.extra_info.third_modal.items.length > 0 && this.userData.extra_info.third_modal.items.find(el => el.name === 'Industria en la que trabaja') && (this.localRequirements.work_industry.rejected || this.localRequirements.work_industry.rejected === null)){
            console.log('rejected')
            const isKnownIndustry = this.workIndustries.find(el => el.answer === this.localRequirements.work_industry.value[0].answer)
            if (!isKnownIndustry){
                this.preSelectedWorkIndustry = this.localRequirements.work_industry.value[0].answer;
                this.currentWorkIndustry = this.workIndustries.find(el => el.answer === 'Otros.');
                this.selectWorkIndustry(this.currentWorkIndustry)
            }
        }
        if (this.userData && this.userData.extra_info && this.userData.extra_info.third_modal && this.userData.extra_info.third_modal.items && this.userData.extra_info.third_modal.items.length > 0 && this.userData.extra_info.third_modal.items.find(el => el.name === 'Rango de ingresos') && !this.localRequirements.monthly_amount.rejected){
            this.preSelectedMonthlyAmount = this.userData.extra_info.third_modal.items.find(el => el.name === 'Rango de ingresos').value;
            this.selectMonthlyAmount(this.monthlyAmounts.find(el => el.answer === this.preSelectedMonthlyAmount))
        }
        this.loader = false;
    }

    goBack(){
        this.$emit('prevStep')
    }

    checkWorkIndustry(){
        if (this.localRequirements.work_industry.value.length > 0 && 
            !this.workIndustries.find(el => el.answer === this.localRequirements.work_industry.value[0].answer)){
            let otherIndustry = this.localRequirements.work_industry.value;
            this.localRequirements.other_industry.value = otherIndustry;
            let answer = this.workIndustries.find(el=> {if (el.answer === 'Otros.') return el});
            this.localRequirements.work_industry.value = []
            this.localRequirements.work_industry.value.push(answer)
            this.workIndustryResponse = true;
        }
    }

    selectMonthlyAmount(answer: Answer){
        this.localRequirements.monthly_amount.value = []
        this.localRequirements.monthly_amount.value.push(answer)
        this.$forceUpdate();
    }

    selectWorkIndustry(answer: Answer){
        this.localRequirements.work_industry.value = []
        this.localRequirements.work_industry.value.push(answer)
        this.currentWorkIndustry = answer;
        const isKnownIndustry = this.workIndustries.find(el => el.answer === answer.answer)
        if (isKnownIndustry && isKnownIndustry.answer !== 'Otros.'){
            this.preSelectedWorkIndustry = '';
        }
        // if (this.localRequirements.work_industry.value[0].answer === 'Otros.'){ 
        //     this.workIndustryResponse = true;
        // }
        // else {
        //     this.workIndustryResponse = false;
        //     this.localRequirements.other_industry.value = null;
        // }
        this.$forceUpdate();
    }

    selectFoundSource(answer: Answer){
        this.localRequirements.funds_source.value = []
        this.localRequirements.funds_source.value.push(answer)
        this.$forceUpdate();
    }

    async submit(){
        this.$v.$touch();
        if (!this.$v.$invalid){ 
            this.loader = true;
            if (this.preSelectedWorkIndustry !== ''){
                const isKnownIndustry = this.workIndustries.find(el => el.answer === this.preSelectedWorkIndustry)
                if (!isKnownIndustry){
                    this.localRequirements.work_industry.value[0].answer = this.preSelectedWorkIndustry;
                }
            }
            this.localLevelQuestions[0].answers = this.localRequirements.monthly_amount.value;
            this.localLevelQuestions[1].answers = this.localRequirements.work_industry.value;
            this.localLevelQuestions[2].answers = this.localRequirements.funds_source.value
            this.localRequirements.answers = this.localLevelQuestions;
            if (!await this.requestLevelTwo(this.localRequirements))
                showToast('Ha ocurrido un error verificando la cuenta','error')
            else {
                // showToast('Solicitud enviada exitosamente','success')
                this.$router.push({name: 'LevelSuccess'}).catch();
            }
            this.loader = false;
        }
    }

    getImageSrc(imageContent: any){
        if (imageContent.data)
            return _arrayBufferToBase64(imageContent.data);
        return _arrayBufferToBase64(imageContent);
    }

    getImage(image: string){
        this.localRequirements.residency_proof.value = image;
    }

    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
        userData!: User;
    /* VERIF LEVELS */
    @verifLevels.Getter(VerifLevelsMethods.getters.GET_LEVELS_QUESTIONS)
        levelQuestions!: Question[];
    @verifLevels.Action(VerifLevelsMethods.actions.FETCH_LEVELS_QUESTIONS)
        fetchQuestions!: (idResidCountry: number) => Promise<boolean>
    @verifLevels.Getter(VerifLevelsMethods.getters.GET_LEVEL_TWO_REQUEST)
        leveltwoRequest!: ReqLevelTwo;
    @verifLevels.Action(VerifLevelsMethods.actions.REQUEST_LEVEL_TWO)
        requestLevelTwo!: (levelTwoRequest: ReqLevelTwo) => Promise<boolean>
     @verifLevels.Getter(VerifLevelsMethods.getters.GET_LEVEL_TWO_REQUEST)
        levelTwoRequest!: ReqLevelTwo
}
